<template>
  <div>
    <loading
      :active.sync="isLoading"
      color="#f8a229"
      loader="dots"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />
    <v-card-text>
      <v-container class="row1">
        <v-row>
          <v-col cols="14" sm="4" md="2">
            <v-row dense class="mb-5">
              <router-link
                v-bind:to="'/admin/admin-bills'"
                style="text-decoration: none"
              >
                <v-btn color="secondary">
                  <v-icon color="black"> mdi-arrow-left </v-icon>
                </v-btn>
              </router-link>
            </v-row></v-col
          >
          <v-col cols="14" sm="8" md="10">
            <v-row dense justify="end" class="mb-5">
              <div>
                <v-btn color="secondary" dark @click="dialog = true">
                  <v-icon color="black"> mdi-plus-circle </v-icon>
                  <v-card-text class="black--text font-weight-bold">
                    Add New Customer
                  </v-card-text>
                </v-btn>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="14" sm="12" md="12">
            <v-row dense class="itemsLayout">
              <v-col lg="2" md="3" sm="3" xs="4" class="pb-3">
                <v-card height="200" width="150" @click="dialog = true">
                  <v-card-text class="text-center">
                    <v-icon
                      color="secondary"
                      style="font-size: 70px"
                      class="mt-11"
                    >
                      mdi-plus
                    </v-icon>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col
                v-for="item in dataFiltered"
                :key="item.customerId"
                lg="2"
                md="3"
                sm="3"
                xs="4"
              >
                <v-card height="200" width="150" class="overflow-hidden">
                  <v-img
                    v-if="!item.image"
                    class="white--text align-end"
                    gradient="rgba(248,162,41,.5), rgba(0,0,0,.2)"
                    color="red"
                    width="150"
                    height="50"
                  >
                    <div style="text-align: center">
                      <v-icon class="text-h2" color="white">
                        mdi-account
                      </v-icon>

                      <v-tooltip top color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            style="
                              margin-top: -6px;
                              float: left;
                              background-color: white;
                              border-radius: 0px 10px 10px 0px;
                            "
                          >
                            <v-icon
                              color="red"
                              @click="deleteItem(item)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-delete-circle
                            </v-icon>
                          </div>
                        </template>
                        <span>Delete {{ item.firstName }}</span>
                      </v-tooltip>

                      <v-tooltip top color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            style="
                              margin-top: 20px;
                              float: right;
                              background-color: white;
                              border-radius: 15px 0px 0px 0px;
                            "
                          >
                            <v-icon
                              color="black"
                              @click="viewEditItem(item)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-information
                            </v-icon>
                          </div>
                        </template>
                        <span>More Info</span>
                      </v-tooltip>
                    </div>
                  </v-img>

                  <v-card-text style="cursor: pointer" class="black--text">
                    <div class="headerClass" style="font-weight: bold">
                      <v-icon style="font-size: 20px" class="mb-1">
                        mdi-account
                      </v-icon>

                      {{ item.firstName }} {{ item.lastName }}
                    </div>
                    <div class="">
                      <v-icon style="font-size: 20px" class="mb-1">
                        mdi-phone
                      </v-icon>

                      {{ item.mobile }}
                    </div>
                    <div class="headerClass">
                      <v-icon style="font-size: 20px" class="mb-1">
                        mdi-email
                      </v-icon>

                      {{ item.email }}
                    </div>
                    <div>
                      <v-chip
                        label
                        color="red"
                        class="white--text"
                        style="height: 25px"
                        v-if="item.isBlackListed === true"
                      >
                        In Active
                      </v-chip>
                      <v-chip
                        label
                        color="green"
                        class="white--text"
                        style="height: 25px"
                        v-if="item.isBlackListed === false"
                      >
                        Active
                      </v-chip>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-col cols="14" sm="12" md="12">
              <v-pagination
                v-model="page"
                :length="pageCount"
                @click="changePage"
                circle
                color="black"
              ></v-pagination>
            </v-col>
          </v-col>
        </v-row>
        <v-col cols="14" sm="8" md="4" class="float-right mt-n15">
          <v-row dense class="mb-5 float-right">
            <v-tooltip left color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  @click="dialogTrash = true"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="black"> mdi-delete-restore </v-icon>
                </v-btn>
              </template>
              <span>Trash</span>
            </v-tooltip>
          </v-row></v-col
        >
      </v-container>

      <v-dialog v-model="dialogViewItem" max-width="500px" origin="top right">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="dialogViewItem = false">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title style="font-size: 20px">
            Customer Info
            <v-tooltip right color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="secondary"
                  @click="editItem()"
                  style="margin-left: 10px"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-circle-edit-outline
                </v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <!-- <v-tooltip right color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="secondary"
                  @click="editImage()"
                  style="margin-left:10px"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-image-plus
                </v-icon>
              </template>
              <span>Edit Image</span>
            </v-tooltip> -->
          </v-card-title>

          <v-card-title
            style="
              font-size: 18px;
              font-weight: bold;
              text-transform: uppercase;
            "
          >
            {{ viewItem.firstName }} {{ viewItem.lastName }}
          </v-card-title>
          <v-divider class="mx-4 mb-4"></v-divider>

          <v-card-text>
            <span style="color: black; font-weight: bold"> Address </span>
            <br />
            <v-icon> mdi-map-marker </v-icon>
            {{ viewItem.address }}, {{ viewItem.city }}, {{ viewItem.state }},

            {{ viewItem.country }},

            {{ viewItem.postcode }}
            <br />

            <span style="color: black; font-weight: bold"> Contact </span>
            <br />
            <v-icon> mdi-phone </v-icon>
            {{ viewItem.telephone }}
            <v-icon> mdi-phone </v-icon>
            {{ viewItem.mobile }}
            <v-icon> mdi-email </v-icon>
            {{ viewItem.email }}
          </v-card-text>

          <v-card-text>
            <v-chip
              label
              color="green"
              class="white--text"
              v-if="viewItem.isBlackListed === false"
            >
              Active
            </v-chip>
            <v-chip
              label
              color="red"
              class="white--text"
              v-if="viewItem.isBlackListed === true"
            >
              In Active
            </v-chip>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog" max-width="750px" origin="top right">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="close">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="editedForm">
                <v-row>
                  <v-col cols="4" class="mb-n8">
                    <v-text-field
                      v-model="editedItem.firstName"
                      label="First Name"
                      outlined
                      clearable
                    />
                  </v-col>

                  <v-col cols="4" class="mb-n8">
                    <v-text-field
                      v-model="editedItem.lastName"
                      label="Last Name"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="4" class="mb-n8">
                    <v-text-field
                      v-model="editedItem.email"
                      label="Email"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="4" class="mb-n8">
                    <v-text-field
                      v-model="editedItem.telephone"
                      label="Telephone"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="4" class="mb-n8">
                    <v-text-field
                      v-model="editedItem.mobile"
                      label="Mobile"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="4" class="mb-n8">
                    <v-text-field
                      v-model="editedItem.address"
                      label="Address Line"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="4" class="mb-n8">
                    <v-text-field
                      v-model="editedItem.postcode"
                      label="Postcode"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="4" class="mb-n8">
                    <v-text-field
                      v-model="editedItem.state"
                      label="State"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="4" class="mb-n8">
                    <v-text-field
                      v-model="editedItem.city"
                      label="City"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="4" class="mb-n8">
                    <v-text-field
                      v-model="editedItem.country"
                      label="Country"
                      outlined
                      clearable
                    />
                  </v-col>

                  <v-col cols="4" class="mb-n8">
                    <v-select
                      v-model="editedItem.isBlackListed"
                      label="Status"
                      :items="status"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="4" class="mb-n8">
                    <v-text-field
                      v-model="editedItem.maxCredit"
                      label="Max Credit"
                      outlined
                      clearable
                      type="number"
                    />
                  </v-col>
                  <v-col v-if="!titleChange == 0" cols="4" class="mb-n8">
                    <v-text-field
                      v-model="userEntity.role"
                      label="User Role"
                      disabled
                      outlined
                    />
                  </v-col>
                  <v-col v-if="!titleChange == 0" cols="4" class="mb-n8">
                    <v-text-field
                      v-model="userEntity.username"
                      label="Username"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col v-if="!titleChange == 0" cols="4" class="mb-n8">
                    <v-text-field
                      v-model="userEntity.password"
                      label="Password"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col v-if="!titleChange == 0" cols="4" class="mb-n8">
                    <v-text-field
                      v-model="userEntity.conformPassword"
                      label="Confirm Password"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <!-- <v-btn color="blue darken-1" text @click="close">
              Cancel
            </v-btn> -->
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="validate"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogImage" max-width="500px" origin="top right">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="dialogImage = false">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title>
            <span class="headline"> Add Image</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="imageForm">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="viewItem.firstName"
                      label="Customer"
                      outlined
                      readonly
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-file-input
                      id="imageFile"
                      v-model="editedImage.formFile"
                      accept="image/png, image/jpeg, image/bmp"
                      prepend-icon="mdi-camera"
                      label="Image"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <!-- <v-btn color="blue darken-1" text @click="close">
              Cancel
            </v-btn> -->
            <v-btn color="blue darken-1" text @click="dialogImage = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="addImageToDb">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogTrash" max-width="1200">
        <v-card class="pa-10">
          <v-row style="float: right">
            <div>
              <v-icon color="closeButton" @click="dialogTrash = false">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          >
          </v-text-field>

          <v-data-table
            :headers="headers"
            :items="trashed"
            :items-per-page="5"
            :search="search"
          >
            <!-- <template v-slot:[`item.isInactive`]="{ item }">
              <div>
                <v-chip
                  label
                  style="color:green; width:100%; justify-content:center;"
                  v-if="item.isInactive === false"
                >
                  Active
                </v-chip>
                <v-chip
                  label
                  style="color:red;  width:100%;  justify-content:center;"
                  v-if="item.isInactive === true"
                >
                  In Active
                </v-chip>
              </div>
            </template> -->

            <template v-slot:[`item.restore`]="{ item }">
              <v-icon color="secondary" @click="restoreItem(item)">
                mdi-restore
              </v-icon>
            </template>

            <template v-slot:[`item.delete`]="{ item }">
              <v-icon color="#FF0000" @click="permanentDelete(item)">
                mdi-trash-can-outline
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogRestore" max-width="500px">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="closeRestore">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title class="headline">
            Are you sure you want to restore
            {{ editedItem.departmentName }} ?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeRestore">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="restoreItemConfm">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="closeDelete">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title class="headline">
            Are you sure you want to delete {{ editedItem.departmentName }}?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="trashItem"> OK </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogPermanentDelete" max-width="500px">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="closeDelete">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title class="headline">
            Are you sure you want to delete
            {{ editedItem.firstName }} {{ editedItem.lastName }} permanently?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
    <!-- <v-file-input
      id="imageFile"
      v-model="editedImage.formFile"
      accept="image/png, image/jpeg, image/bmp"
      prepend-icon="mdi-camera"
      label="Image"
      outlined
      clearable
      style="display:none"
    /> -->
  </div>
</template>

<style>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
}
.row1 {
  margin-top: -25px;
}
.itemsLayout {
  height: 415px;
  overflow: hidden;
  overflow-y: auto;
}
.addbutton {
  float: right;
}
.temp {
  background-color: rgb(255, 255, 255);
}
</style>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import MaterialCard from "../components/MaterialCard.vue";

export default {
  components: { MaterialCard, Loading },
  data() {
    return {
      isLoading: false,
      loadingSize: 50,
      loadingFullPage: true,
      loading: false,
      dialogViewItem: false,
      viewItem: [],
      dialogTrash: false,
      dialog: false,
      dialogDelete: false,
      dialogPermanentDelete: false,
      dialogRestore: false,
      dateModal: false,
      search: "",

      headers: [
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "", value: "restore" },
        { text: "", value: "delete" },
      ],

      status: [
        { text: "Acitve ", value: false },
        { text: "InActive ", value: true },
      ],
      titleChange: -1,
      editedIndex: "",
      editedItem: {
        firstName: "",
        lastName: "",
        email: "",
        telephone: "",
        mobile: "",
        isBlackListed: false,
        address: "",
        postcode: "",
        state: "NSW",
        city: "",
        country: "Australia",
        customerUserId: "",
        maxCredit: "1000",
      },

      defaultItem: {
        firstName: "",
        lastName: "",
        email: "",
        telephone: "",
        mobile: "",
        isBlackListed: false,
        address: "",
        postcode: "",
        state: "NSW",
        city: "",
        country: "Australia",
        customerUserId: "",
        maxCredit: "1000",
      },

      userEntity: {
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        password: "",
        conformPassword: "",
        phoneNumber: "",
        role: "Customer",
      },
      userEntity: {
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        password: "",
        conformPassword: "",
        phoneNumber: "",
        role: "Customer",
      },

      trashed: [],
      page: 1,
      pageCount: null,
      start: 0,
      end: 11,
      dataFiltered: [],

      filtered: {
        orderBy: "",
        orderDirection: "desc",
        skip: 0,
        take: 11,
        searchValue: "",
        searchColumn: "",
        clubId: "",
      },
      formData: null,

      dialogImage: false,
      editedImage: {
        customerId: "",
        formFile: "",
      },
      imageFormData: null,

      userRoles: [],
    };
  },

  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Customer" : "Edit Customer";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogPermanentDelete(val) {
      val || this.closeDelete();
    },
    dialogRestore(val) {
      val || this.closeRestore();
    },
    page: function () {
      this.changePage();
    },
    // dialogViewItem() {
    //   val || this.viewItem;
    // }
  },

  created() {
    this.getDataFromApi();
    this.getUserRoles();

    //  this.getFilteredData();
  },

  methods: {
    getUserRoles() {
      this.isLoading = true;
      this.$http
        .get("Roles")
        .then((response) => {
          if (response.data.success === true) {
            this.userRoles = response.data.data;
          } else {
          }
        })
        .catch((error) => {});
    },
    getDataFromApi() {
      var pagination = { pageNumber: 1, pageSize: 10000 };
      this.isLoading = true;
      this.$http
        .post("/Customers/Paginated", null, { params: pagination })
        .then((response) => {
          this.customers = response.data.data.items;
          this.customers = this.customers.reverse();

          this.$http
            .post("/Customers/Archived", pagination)
            .then((response) => {
              this.trashed = response.data.data.items;
            });

          this.changePage();
        })
        .catch((error) => {
          this.isLoading = false;
          if (error) {
            this.$toast.error("Error in getting Data", "Error", {
              position: "topRight",
            });
          }
        });
    },
    // getFilteredData() {
    //   this.$http
    //     .post("/Department/GetAllByFilter", this.filtered)
    //     .then(response => {
    //       this.dataFiltered = response.data.data;
    //     });
    // },
    updateData() {
      this.page = 1;
      this.changePage();
    },
    changePage() {
      // var diff = this.page - 1;
      // this.filtered.skip = this.start + this.end * diff;
      // this.getFilteredData();

      var count = this.customers.length;
      var float = count / this.end;
      var int = parseInt(count / this.end);
      if (float > int) {
        this.pageCount = int + 1;
      } else {
        this.pageCount = int;
      }

      var diff = this.page - 1;
      var newStart = this.start + this.end * diff;
      var newEnd = this.end + this.end * diff;

      const items = this.customers.slice(newStart, newEnd);

      this.dataFiltered = items;
      this.isLoading = false;
    },
    viewEditItem(item) {
      this.editedIndex = this.dataFiltered.indexOf(item);
      this.viewItem = Object.assign({}, item);
      this.dialogViewItem = true;
    },

    editItem() {
      this.editedItem = Object.assign({}, this.viewItem);
      this.titleChange = 0;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.dataFiltered.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    restoreItem(item) {
      this.editedIndex = this.trashed.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogRestore = true;
    },
    permanentDelete(item) {
      this.editedIndex = this.trashed.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogPermanentDelete = true;
    },

    trashItem() {
      this.isLoading = true;
      var itemId = this.editedItem.customerId;
      this.$http
        .get(`/Customers/${itemId}/Archive`)
        .then((response) => {
          if (response.data.success === true) {
            this.$toast.success(response.data.message, "Success", {
              position: "topRight",
            });
            this.customers.splice(this.editedIndex, 1);
            this.updateData();
            this.closeDelete();
            this.trashed.unshift(this.editedItem);

            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status) {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        });
    },

    restoreItemConfm() {
      this.isLoading = true;
      var itemId = this.editedItem.customerId;
      this.$http
        .get(`/Customers/${itemId}/Restore`)
        .then((response) => {
          if (response.data.success === true) {
            this.trashed.splice(this.editedIndex, 1);
            this.customers.unshift(response.data.data);
            this.updateData();

            // this.customers.splice(this.editedIndex, 1);
            // this.updateData();
            // this.closeDelete();
            // this.trashed.unshift(this.editedItem);

            this.$toast.success(response.data.message, "Success", {
              position: "topRight",
            });
          } else {
            this.isLoading = false;
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status) {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        });

      this.dialogRestore = false;
    },
    deleteItemConfirm() {
      this.isLoading = true;
      var itemId = this.editedItem.customerId;

      this.$http
        .delete("/Customers/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.$toast.success(response.data.message, "Success", {
              position: "topRight",
            });
            this.trashed.splice(this.editedIndex, 1);
          }
          this.isLoading = false;
        })

        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 404) {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        });

      this.closeDelete();
    },
    setDefault() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.titleChange = -1;
      });
    },
    close() {
      this.setDefault();
      this.dialog = false;
    },

    closeDelete() {
      this.setDefault();
      this.dialogDelete = false;
      this.dialogPermanentDelete = false;
    },

    closeRestore() {
      this.setDefault();
      this.dialogRestore = false;
    },

    validate() {
      if (this.titleChange === -1) {
        this.userEntity.firstName = this.editedItem.firstName;
        this.userEntity.lastName = this.editedItem.lastName;
        this.userEntity.email = this.editedItem.email;
        this.userEntity.phoneNumber = this.editedItem.mobile;

        if (
          this.userEntity.username == "" ||
          this.userEntity.phoneNumber == "" ||
          this.userEntity.email == "" ||
          this.userEntity.password == "" ||
          this.userEntity.conformPassword == "" ||
          this.userEntity.role == "" ||
          this.userEntity.password != this.userEntity.conformPassword
        ) {
          if (this.userEntity.firstName == "") {
            this.$toast.error("First Name Required", "Error", {
              position: "topRight",
            });
          }

          if (this.userEntity.username == "") {
            this.$toast.error("User Name Required", "Error", {
              position: "topRight",
            });
          }
          if (this.userEntity.email == "") {
            this.$toast.error("Email Required", "Error", {
              position: "topRight",
            });
          }
          if (this.userEntity.phoneNumber == "") {
            this.$toast.error("Mobile Number Required", "Error", {
              position: "topRight",
            });
          }
          if (this.userEntity.role == "") {
            this.$toast.error("User Role Required", "Error", {
              position: "topRight",
            });
          }
          if (this.userEntity.password == "") {
            this.$toast.error("Password Required", "Error", {
              position: "topRight",
            });
          }
          if (this.userEntity.conformPassword == "") {
            this.$toast.error("Confirm Password Required", "Error", {
              position: "topRight",
            });
          }
          if (
            this.userEntity.password != "" &&
            this.userEntity.conformPassword != "" &&
            this.userEntity.password != this.userEntity.conformPassword
          ) {
            this.$toast.error("Password Mismatch", "Error", {
              position: "topRight",
            });
          }
        } else {
          this.createUser();
        }
      } else {
        this.updateCustomer();
      }
    },
    createUser() {
      //  this.userEntity.firstName = this.editedItem.firstName;
      //  this.userEntity.lastName = this.editedItem.lastName;
      this.userEntity.email = this.editedItem.email;
      this.userEntity.phoneNumber = this.editedItem.mobile;
      this.userEntity.maxCredit = this.userEntity.maxCredit || 0;

      this.isLoading = true;
      if (
        this.editedItem.customerUserId == "" ||
        this.editedItem.customerUserId == null
      ) {
        this.$http
          .post("Users", this.userEntity)
          .then((response) => {
            if (response.data.success === true) {
              this.editedItem.customerUserId = response.data.data.userId;
              localStorage.setItem(
                "CreateUserResponseUserId",
                response.data.data.userId
              );
              this.updateCustomer();

              this.isLoading = false;
            } else {
              this.isLoading = false;
              this.$toast.error(response.data.message, "Error", {
                position: "topRight",
              });
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.$toast.error(error.response.status, "Error", {
              position: "topRight",
            });
          });
      } else {
        this.updateCustomer();
      }
    },

    updateCustomer() {
      if (
        this.editedItem.customerUserId == "" ||
        this.editedItem.customerUserId == null
      ) {
        this.editedItem.customerUserId = localStorage.getItem(
          "CreateUserResponseUserId"
        );
      }

      this.save();
    },

    save() {
      this.isLoading = true;
      if (this.titleChange === -1) {
        this.$http
          .post("/Customers", this.editedItem)
          .then((response) => {
            if (response.data.success === true) {
              this.customers.unshift(response.data.data);
              this.updateData();
              localStorage.removeItem("CreateUserResponseUserId");
              this.$toast.success("Customers added successfully.", "Success", {
                position: "topRight",
              });
            } else {
              this.isLoading = false;
              this.$toast.error(response.data.message, "Error", {
                position: "topRight",
              });
            }
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response.status) {
              this.$toast.error("Adding Process Failed", "Error", {
                position: "topRight",
              });
            }
          });
      } else {
        var id = this.editedItem.customerId;

        this.$http
          .put("/Customers/" + id, null, { params: this.editedItem })
          .then((response) => {
            if (response.data.success === true) {
              this.viewItem = Object.assign({}, response.data.data);
              this.customers.splice(this.editedIndex, 1);
              this.customers.unshift(response.data.data);
              this.updateData();
              this.$toast.success("Customers Edited successfully.", "Success", {
                position: "topRight",
              });
            } else {
              this.isLoading = false;
              this.$toast.error(response.data.message, "Error", {
                position: "topRight",
              });
            }
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response.status) {
              this.$toast.error("Editing Process Failed", "Error", {
                position: "topRight",
              });
            }
          });
      }
      this.close();
    },
    editImage() {
      this.editedItem = Object.assign({}, this.viewItem);
      this.addImage(this.editedItem);
    },
    addImage(item) {
      this.editedIndex = this.dataFiltered.indexOf(item);
      this.viewItem = Object.assign({}, item);

      this.editedImage.customerId = item.customerId;
      this.editedImage.formFile = null;
      this.dialogImage = true;
      // document.getElementById("imageFile").click();
    },

    addImageToDb() {
      this.imageFormData = new FormData();

      this.imageFormData.append("customerId", this.editedImage.customerId);
      this.imageFormData.append("image", this.editedImage.formFile);

      this.saveImage();
    },
    saveImage() {
      this.isLoading = true;
      this.$http
        .post("/Customers/Image", this.imageFormData)
        .then((response) => {
          if (response.data.success === true) {
            this.$toast.success(response.data.message, "Success", {
              position: "topRight",
            });

            this.dialogImage = false;

            this.$http.get("/Customers").then((response) => {
              this.customers = response.data.data;
              this.customers = this.customers.reverse();

              this.changePage();
            });
          } else {
            this.isLoading = false;
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status) {
            this.$toast.error("Adding Process Failed", "Error", {
              position: "topRight",
            });
          }
        });
    },
    getStatusColor(data) {
      switch (data) {
        case "true":
          return "success";
        case "false":
          return "error";
        default:
          return "";
      }
    },
  },
};
</script>
